<template>
    <v-container fluid fill-height class="d-flex pa-0 ma-0 primary">
        <v-col cols="3" class="primary d-flex align-center">
            <v-img contain class="mb-8" max-height="150" :src="require('@/assets/oomphlogohome.png')"></v-img>
        </v-col>
        <v-col cols="9" class="white d-flex align-self-stretch justify-center">
            <v-card width="550" class="pa-8 rounded-sm elevation-0 align-self-center">
                <v-card-title class="d-flex justify-start">
                    <span class="headline py-4">Register account</span>
                </v-card-title>
                <v-card-text class="pt-6">
                    <v-form ref="form" lazy-validation>
                        <v-text-field outlined class="primary--text" v-model.trim="form.userName" label="Full Name" append-icon="oomph-verified-account" :rules="nameRules" required></v-text-field>
                        <v-text-field outlined class="primary--text" v-model="form.userEmail" label="Email" type="email" append-icon="oomph-secured-letter" :rules="emailRules" required></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="form.userTelephone"
                            @input="acceptNumber"
                            label="Telephone"
                            type="number"
                            append-icon="oomph-phone"
                            :rules="telephoneNumberRules"
                            required
                        ></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="password"
                            label="Password"
                            :append-icon="showPassword ? 'oomph-unavailable' : 'oomph-lock'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            :rules="passwordRules"
                            autocomplete="off"
                            required
                        ></v-text-field>
                        <v-text-field
                            outlined
                            class="primary--text"
                            v-model="confirmPassword"
                            label="Confirm Password"
                            :append-icon="showConfirmPassword ? 'oomph-unavailable' : 'oomph-lock'"
                            :type="showConfirmPassword ? 'text' : 'password'"
                            @click:append="showConfirmPassword = !showConfirmPassword"
                            :rules="[password === confirmPassword || 'Password must match']"
                            autocomplete="off"
                            required
                        ></v-text-field>
                    </v-form>
                    <Alert border="left" :message="alertMessage" :value="value" :type="type" />
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn
                        block
                        x-large
                        class="primary white--text mb-8 elevation-0"
                        @click="register()"
                        :disabled="form.userName === '' || form.userEmail === '' || form.password === '' || form.confirmPassword === '' || loading"
                        :loading="loading"
                        >Register</v-btn
                    >
                </v-card-actions>
                <div class="body-1 float-right pr-4">Already have an account? <span class="font-weight-bold quaternary--text" style="cursor: pointer" @click="$router.push('/')">Login</span></div>
            </v-card>
        </v-col>
        <v-col cols="12" v-if="!$vuetify.breakpoint.mobile" class="py-0 white d-flex align-self-stretch justify-end">
            <v-row >
                <v-col cols="3" class="primary"></v-col>
                <v-col cols="9" class="white d-flex justify-end py-4">
                    <span class="d-flex align-self-end">Powered by</span>
                    <v-img contain class="ml-2 mb-1 d-flex align-self-end" max-height="50" max-width="100" :src="require('@/assets/vindico.png')"></v-img>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';

export default {
    data: () => ({
        alert: false,
        alertMessage: '',
        emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
        form: {
            userEmail: '',
            userName: '',
            userTelephone: '',
            userGroup: '',
            userLevel: '',
            deleted: '0'
        },
        loading: false,
        nameRules: [(v) => !!v || 'Name is required'],
        passwordRules: [(v) => !!v || 'Password is required'],
        showPassword: false,
        showConfirmPassword: false,
        telephoneNumberRules: [(v) => !!v || 'Telephone is required', (v) => (v && v.length <= 14 && v.length > 2) || 'Telephone must be less than 14 characters and more than 2'],
        type: 'error',
        password: "",
        confirmPassword: "",
        user: {
            id: '',
            email: '',
            userName: '',
            photoURL: '',
            created: '',
            updated: '',
            deleted: '0'
        },
        value: false,
    }),
    methods: {
        acceptNumber() {
            return this.form.userTelephone.replace(/\D/g, '').match(/(\d{0,5})(\d{0,7})/);
        },
        register() {
            if (this.$refs.form.validate()) {
                var t = this;
                t.loading = true;
                t.form.orgId = 'OOMPHWELLNESS0000001',
                t.form.orgName = 'Oomph Wellness';
                t.form.userGroup = 'Guest';
                t.form.userLevel = 'GuestUser';
                t.form.userStatus = 'Pending';
                const auth = getAuth();
                createUserWithEmailAndPassword(auth, t.form.userEmail, t.password)
                    .then(async (userCredential) => {
                        const user = userCredential.user;
                        // t.user.userName = t.form.userName;
                        await updateProfile(auth.currentUser, {
                            displayName: t.form.userName,
                        });
                        t.setFirebaseUser(user.uid);
                        await t.MIX_firestore_create(t.form, 'users');
                        sendEmailVerification(auth.currentUser).then(() => {
                            t.alertMessage = 'Email verification sent, please confirm your email address to login.';
                            t.type = 'success';
                            t.value = true;
                            t.loading = false;
                            t.$router.push('/holding');
                        });
                    })
                // .then(() => {
                //     // * REDIRECT USER TO HOMEPAGE
                //     t.$router.push("/").catch((error) => {
                //         console.log(error.message);
                //     });
                // })
                    .catch((error) => {
                        t.alertMessage = error.message;
                        t.value = true;
                        t.loading = false;
                    });
            }
        },
        setFirebaseUser(uid) {
            var t = this;
            t.form.id = uid;
            t.form.created = t.$moment().format('x');
        },
    },
};
</script>