<template>
    <v-snackbar dark bottom v-model="alertBox.show" :color="alertBox.color" :timeout="alertBox.timeout">
        <span class="white--text">{{ alertBox.message }}</span>

        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="MIX_alertBox({ color: '', timeout: 0, message: '', show: false })"> Close </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            alertBox: 'GET_alertBox',
        }),
    },
    created() {
        // console.log('alertBox created');
    }
};
</script>
