<template>
    <v-container fluid fill-height class="d-flex pa-0 ma-0 primary">
        <v-col cols="3" class="primary d-flex align-center">
            <v-img contain class="mb-8" max-height="150" :src="require('@/assets/oomphlogohome.png')"></v-img>
        </v-col>
        <v-col cols="9" class="white d-flex align-self-stretch justify-center">
            <v-card width="550" class="pa-8 rounded-sm elevation-0 align-self-center">
                <v-card-title class="d-flex justify-start">
                    <span class="headline py-4">Reset Password</span>
                </v-card-title>
                <v-card-subtitle class="d-flex justify-start pb-10 black--text">Please enter your email address to request a password reset</v-card-subtitle>
                <v-card-text class="pt-3">
                    <v-form ref="form" lazy-validation>
                        <v-text-field outlined class="primary--text" v-model="form.userEmail" label="Email" type="email" append-icon="oomph-secured-letter" :rules="emailRules" required></v-text-field>
                    </v-form>
                    <!-- <Alert border="left" :message="alertMessage" :value="value" :type="type" /> -->
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn
                        block
                        x-large
                        class="primary white--text mb-8 elevation-0"
                        @click="reset()"
                        :disabled="form.userEmail === '' || loading"
                        :loading="loading"
                        >Reset</v-btn
                    >
                </v-card-actions>
                <div class="body-1 float-right pr-4">Already have an account? <span class="font-weight-bold quaternary--text" style="cursor: pointer" @click="$router.push('/')">Login</span></div>
            </v-card>
        </v-col>
        <v-col cols="12" v-if="!$vuetify.breakpoint.mobile" class="py-0 white d-flex align-self-stretch justify-end">
            <v-row >
                <v-col cols="3" class="primary"></v-col>
                <v-col cols="9" class="white d-flex justify-end py-4">
                    <span class="d-flex align-self-end">Powered by</span>
                    <v-img contain class="ml-2 mb-1 d-flex align-self-end" max-height="50" max-width="100" :src="require('@/assets/vindico.png')"></v-img>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

export default {
    data: () => ({
        alert: false,
        alertMessage: '',
        emailRules: [(v) => !!v || 'Email is required', (v) => /.+@.+/.test(v) || 'Email must be valid'],
        form: {
            email: '',
        },
        loading: false,
        type: 'error',
        user: {
            id: '',
            email: '',
            userName: '',
            photoURL: '',
            created: '',
            updated: '',
        },
        value: false,
    }),
    methods: {
        reset() {
            if (this.$refs.form.validate()) {
                var t = this;
                t.loading = true;
                // t.form.orgId = 'OOMPHWELLNESS0000001',
                // t.form.orgName = 'Oomph Wellness';
                // t.form.userGroup = 'Guest';
                const auth = getAuth();

				sendPasswordResetEmail(auth, t.form.userEmail)
					.then(function() {
						// Email sent.
						t.MIX_alertBox({ color: "success", timeout: 2000, message: "A link to reset your password has been sent to your email address. Check your inbox.", show: true });
                            // t.value = true;
                            // t.type = 'success';
                            // t.alertMessage = 'A link to reset your password has been sent to your email address. Check your inbox.';
                         t.loading = false;
                            t.$router.push('/');
						// console.log('email sent');
					})
					.catch(function(error) {
						// console.log('err: ' + error);
						t.MIX_alertBox({ color: "error", timeout: 2000, message: "There was an error", show: true });
					});
            }
        },
        setFirebaseUser(uid) {
            var t = this;
            t.form.id = uid;
            t.form.created = t.$moment().format('x');
        },
    },
};
</script>