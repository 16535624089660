import { mapActions } from "vuex";

// * GLOBAL MIXIN
const mixin = {
    methods: {
        ...mapActions({
			ACT_FIREBASE_userAuth: 'firebase_auth_store/ACT_FIREBASE_userAuth',
		}),
        MIX_FIREBASE_userAuth(user) {
            this.ACT_FIREBASE_userAuth(user)
        },
        // MIX_FIREBASE_userAuthClaims(user) {

        // },
        MIX_FIREBASE_userData(user) {
            this.ACT_FIREBASE_userAuth(user)
        },		
        MIX_FIREBASE_logout() {
            let t = this;
            this.ACT_FIREBASE_userAuth(null)
            t.$firebase.auth.signOut().then(() => {
                // t.showPageElements = false;
                t.$router.push("/login").catch((err) => {
                    console.log("Route error: " + err);
                });
            });
          }
    }
}

export default {
    install (Vue) {
      Vue.mixin(mixin)
    }
  }