/* eslint-disable */
import { doc, setDoc, getDoc, getDocs, deleteDoc, collection, query, where, orderBy, limit, startAt } from "firebase/firestore";

// * GLOBAL MIXIN
const mixin = {
	data: () => ({}),
	methods: {
		// Create a new Firestore Document
		async MIX_firestore_create(document, collectionName) {
			try {
				// Add a new document in collectionName requires id to be passed
				return await setDoc(doc(this.$firebase.db, collectionName, document.id), document).then(() => {
					return { code: 1, mesage: "Firestore Document Created Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Creating Firestore Document", data: null, error: error };
			}
		},
		// Read a Firestore Document
		async MIX_firestore_read(documentId, collectionName) {
			try {
				// Reads a document in collectionName requires id to be passed
				const docRef = doc(this.$firebase.db, collectionName, documentId);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					return { code: 1, mesage: "Firestore Document Read Successfully", data: docSnap.data(), error: null };
				} else {
					return { code: 0, mesage: "Firestore Document does not exist", data: null, error: null };
				}
			} catch (error) {
				return { code: -1, mesage: "Error Occured Reading Firestore Document", data: null, error: error };
			}
		},
		// Read Many Firestore Documents
		async MIX_firestore_readMany(collectionName, showDeleted, orderField, orderDirection, limitNumber) {
			try {
				// Reads all Documents in the Firestore collectionName
				let q = null
				let conditions = []
				let documents = [];
				const collectionRef = collection(this.$firebase.db, collectionName);
				// // Loop through and add Where Clauses to Conditions
				// for (var i = 0; i < whereConditions.length; i++) {
				// 	conditions.push(where(whereConditions[i].field, whereConditions[i].operator, whereConditions[i].value));
				// }
				if (orderField !== null && orderField !== undefined) {
					if (orderDirection === 'desc') { 
						conditions.push(orderBy(orderField, orderDirection))
					} else {
						conditions.push(orderBy(orderField))
					}
				}
				if (limitNumber !== null && limitNumber !== undefined) {
					conditions.push(limit(limitNumber))
				}
				q = query(collectionRef);
				if (conditions.length === 0) {
					q = query(collectionRef);
				} else {
					q = query(collectionRef, ...conditions);
				}
				let querySnapshot = await getDocs(q)
				// Loop through Results
				querySnapshot.forEach((doc) => {
					if (showDeleted) {
						documents.push(doc.data())
					} else {
						if (!doc.data().deleted) {
							documents.push(doc.data());
						}	
					}
				})
				return { code: 1, mesage: "Firestore Documents Read Successfully", data: documents, error: null };
					
			} catch (error) {
				console.log(error)
				return { code: -1, mesage: "Error Occured Reading Firestore Documents", data: null, error: error };
			}
		},
		// Read Many Firestore Documents
		async MIX_firestore_readManyWhere(collectionName, showDeleted, whereConditions, limitNumber) {
			try {
				// Reads all Documents in the Firestore collectionName where conditions are defined
				let q = null
				let conditions = []
				let documents = [];
				const collectionRef = collection(this.$firebase.db, collectionName);
				// // Loop through and add Where Clauses to Conditions
				for (var i = 0; i < whereConditions.length; i++) {
					conditions.push(where(whereConditions[i].field, whereConditions[i].operator, whereConditions[i].value));
				}
				if (limitNumber !== null && limitNumber !== undefined) {
					conditions.push(limit(limitNumber))
				}
				q = query(collectionRef);
				if (conditions.length === 0) {
					q = query(collectionRef);
				} else {
					q = query(collectionRef, ...conditions);
				}
				let querySnapshot = await getDocs(q)
				// Loop through Results
				querySnapshot.forEach((doc) => {
					if (showDeleted) {
						documents.push(doc.data())
					} else {
						if (!doc.data().deleted) {
							documents.push(doc.data());
						}	
					}
				})
				return { code: 1, mesage: "Firestore Documents Read Successfully", data: documents, error: null };
					
			} catch (error) {
				console.log(error)
				return { code: -1, mesage: "Error Occured Reading Firestore Documents", data: null, error: error };
			}
		},		

		// Update a Firestore Document
		async MIX_firestore_update(document, collectionName, fields) {
			try {
				// Updates a document in collectionName requires id to be passed
				const documentRef = doc(this.$firebase.db, collectionName, document);
				return await setDoc(documentRef, fields, { merge: true }).then(() => {
					return { code: 1, mesage: "Firestore Document Updated Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Updating Firestore Document", data: null, error: error };
			}
		},
		// Delete a Firestore Document (Mark as Delete)
		async MIX_firestore_delete(document, collectionName, fields) {
			try {
				// Updates a document in collectionName requires id to be passed
				const documentRef = doc(this.$firebase.db, collectionName, document.id);
				return await setDoc(documentRef, fields, { merge: true }).then(() => {
					return { code: 1, mesage: "Firestore Document Deleted Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Deleting/Updating Firestore Document", data: null, error: error };
			}
		},
		// Destroy a Firestore Document (Remove from collectionName)
		async MIX_firestore_destroy(documentId, collectionName) {
			try {
				// Reads a document in collectionName requires id to be passed
				return await deleteDoc(doc(this.$firebase.db, collectionName, documentId)).then(() => {
					return { code: 1, mesage: "Firestore Document Destroyed Successfully", data: null, error: null };
				});
			} catch (error) {
				return { code: -1, mesage: "Error Occured Destroying Firestore Document", data: null, error: error };
			}
		},
	},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
