<template>
    <v-container fluid fill-height class="d-flex flex-column align-center mb-4">
        <v-card width=500 class="pa-8 rounded-lg py-8 my-8">
            <v-card-title class="d-flex justify-center">
                <span class="text-h6 primary--text">Welcome to OOMPH Portal</span>
            </v-card-title>
            <v-card-text class="primary--text">
                <!-- GET_FIREBASE_userAccessToken: <pre>{{ GET_FIREBASE_userAccessToken }}</pre>
                userLevel {{ userLevel }}<br />
                userGroup {{ userGroup }}<br />
                userStatus {{ userStatus }}<br /> -->
                <span v-if="userStatus === 'Pending'" class="mb-4"
                    >Your account status is currently <span class="secondary--text font-weight-bold">PENDING</span>, please wait for your account to be Approved.</span
                >
                <span v-if="userStatus === 'Approved'" class="mb-4">Your account status is currently <span class="success--text font-weight-bold">APPROVED</span>, please click home.</span>
                <v-col cols="12" v-if="userStatus === 'Approved'" class="d-flex justify-center">
                    <v-icon size="62" @click="$router.push('/home')">oomph-home-page</v-icon>
                </v-col>
            </v-card-text>
            <v-card-text class="primary--text">     
                <span>
                    If you are waiting more than 48 hours for your account to be approved please contact
                    <a :href="'mailto:support@oomph-wellness.org'" class="secondary--text font-weight-bold">support@oomph-wellness.org</a></span
                >
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
//import { doc, onSnapshot } from 'firebase/firestore';
import { mapGetters } from 'vuex';

export default {
    name: 'Holding',
    data: () => ({
    }),
    computed: {
        ...mapGetters({
            GET_FIREBASE_userAuth: 'firebase_auth_store/GET_FIREBASE_userAuth',
            GET_FIREBASE_userAccessToken: 'firebase_auth_store/GET_FIREBASE_userAccessToken',
        }),
        userLevel() { return this.GET_FIREBASE_userAccessToken.user_level },
        userGroup() { return this.GET_FIREBASE_userAccessToken.user_group },
        userStatus() { return this.GET_FIREBASE_userAccessToken.user_status }
    },
    // async created() {
    //     await onSnapshot(doc(this.$firebase.db, 'users', this.GET_FIREBASE_userAuth.uid), (doc) => {
    //         this.userStatus = doc.data().userStatus;
    //     });
    // },
};
</script>
