import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

//* Staging config
// const firebaseConfig = {
//     apiKey: "AIzaSyBL1FzMgMs0yuTulDsu0Rv4NEPuo1bYAj8",
//     authDomain: "oomph-staging.firebaseapp.com",
//     projectId: "oomph-staging",
//     storageBucket: "oomph-staging.appspot.com",
//     messagingSenderId: "982315873404",
//     appId: "1:982315873404:web:8bcaf2cd276ad23c5545f9",
//     measurementId: "G-P75SDSWBVF"
// };

//* Live config
const firebaseConfig = {
    apiKey: "AIzaSyCUQm0lIZmMKvIgn5jb5YUCxaXH7tcnSEQ",
    authDomain: "oomph-a7ab3.firebaseapp.com",
    projectId: "oomph-a7ab3",
    storageBucket: "oomph-a7ab3.appspot.com",
    messagingSenderId: "1027309068804",
    appId: "1:1027309068804:web:22bd7dc670be079f9a4b51",
    measurementId: "G-N8RR2RG8KV"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const currentUser = auth.currentUser;
const db = firebase.firestore();
const fv = firebase.firestore.FieldValue;
const privateStorage = firebase.app().storage("gs://oomph-portal-private");
const publicStorage = firebase.app().storage("gs://oomph-portal-public");
const storage = firebase.storage();

export {
    auth,
    currentUser,
    db,
    fv,
    privateStorage,
    publicStorage,
    storage,
};